'use strict';

// Steps slider
{
  const stepsSlider = {
    el: {
      $slider: $('.js_steps-slider')
    },

    init() {
      this.initiateSlick();
    },

    initiateSlick() {
      const _this = this;

      _this.el.$slider.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      });
    }
  };

  if ($('.js_steps-slider').length) {
    stepsSlider.init();
  }
}

// Slide to section links
{
  $('.js_link').on('click', function (e) {
    e.preventDefault();

    const target = $(this).attr('href');
    const offset = $(target).offset().top;

    $('html,body').animate({scrollTop: offset}, 700);

    return 0;
  });
}

// Animations
{
  const animations = {
    el: {
      animatedBlock: '.js_animated',
      animatedBlockSection: '.js_animated-section',
      $animatedBlockSections: $('.js_animated-section'),
      $introSection: $('.js_animated-intro')
    },

    config: {},

    init() {
      this.animateIntro();
      this.animateText();
    },

    animateText() {
      const _this = this;

      const addAnimation = function (blockSectionNum) {
        // Trigger offset
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();

        let triggerOffset = -(windowHeight / 2) * 0.8;
        if (windowWidth <= 767) {
          triggerOffset = -(windowHeight / 2) * 0.8;
        }

        // init controller
        const controller = new ScrollMagic.Controller();

        // build scene
        const scene = new ScrollMagic.Scene({
          triggerElement: `${_this.el.animatedBlockSection}${blockSectionNum}`,
          reverse: false,
          offset: triggerOffset
        })
          .setClassToggle(
            `${_this.el.animatedBlockSection}${blockSectionNum} ${_this.el.animatedBlock}`,
            'animated--active'
          )
          // .addIndicators({name: blockSectionNum}) // add indicators (requires plugin)
          .addTo(controller);
      };

      const numAnimatedSections = _this.el.$animatedBlockSections.length;

      // Set scene for each of the animated text areas
      for (let i = 0; i < numAnimatedSections; i++) {
        addAnimation(i + 1);
      }
    },

    animateIntro() {
      setTimeout(() => {
        this.el.$introSection.addClass('animated--active');
      }, 500);
    }
  };

  animations.init();
}

// Accordion
{
  const accordion = {
    el: {
      $acr: $('.js_acr'),
      toggleClass: '.js_acr_toggle',
      bodyClass: '.js_acr_body'
    },

    init() {
      this.clickHandler();
    },

    clickHandler() {
      const _this = this;

      this.el.$acr.on('click', function () {
        _this.toggleAcr($(this));
      });
    },

    toggleAcr($acr) {
      $acr.find(this.el.bodyClass).slideToggle(300);
      $acr.find(this.el.toggleClass).toggleClass('active');
    }
  };

  if ($('.js_acr').length) {
    accordion.init();
  }
}

// SP navigation
{
  const navSP = {
    el: {
      $navToggle: $('.js_nav-sp-toggle'),
      $navBody: $('.js_nav-sp-body'),
      $navLink: $('.js_nav-sp-body .js_link')
    },

    init() {
      this.toggleHandler();
      this.linkHandler();
    },

    toggleHandler() {
      this.el.$navToggle.on('click', () => {
        this.toggleNav();
      });
    },

    toggleNav() {
      this.el.$navBody.slideToggle();
      this.el.$navToggle.toggleClass('active');
    },

    linkHandler() {
      this.el.$navLink.on('click', () => {
        this.toggleNav();
      });
    }
  };

  if ($('.js_nav-sp-toggle').length) {
    navSP.init();
  }
}

// Go to top button
{
  const goToTopBtn = {
    el: {
      $button: $('.js_go-to-top')
    },

    conf: {
      startOffset: 10
    },

    init() {
      this.scrollHandler();
    },

    scrollHandler() {
      const _this = this;

      let position = $(window).scrollTop();

      $(window).on('scroll', function () {
        const scroll = $(window).scrollTop();

        const scrollTop = $(window).scrollTop();
        // if (scrollTop > window.innerHeight * 0.7 && scroll < position) {
        if (scrollTop > window.innerHeight * 0.7) {
          _this.showButton();
        } else {
          _this.hideButton();
        }

        position = scroll;
      });
    },

    showButton() {
      this.el.$button.addClass('active');
    },

    hideButton() {
      this.el.$button.removeClass('active');
    }
  };

  if ($('.js_go-to-top').length) {
    goToTopBtn.init();
  }
}

// CTA
{
  const cta = {
    el: {
      $cta: $('.js_cta'),
      $closeBtn: $('.js_cta-close')
    },

    init() {
      if (this.hasNotBeenClosed()) {
        this.showCta();
        this.clickHandler();
      }
    },

    showCta() {
      setTimeout(() => {
        this.el.$cta.addClass('active');
      }, 1000);
    },

    clickHandler() {
      const _this = this;

      this.el.$closeBtn.on('click', function (e) {
        e.preventDefault();
        _this.closeCta();
        _this.setClosedCookie();
        return 0;
      });
    },

    setClosedCookie() {
      $.cookie('ctaClosed', true, {expires: 1, path: '/'});
    },

    hasNotBeenClosed() {
      if ($.cookie('ctaClosed')) {
        return false;
      }
      return true;
    },

    closeCta() {
      this.el.$cta.addClass('close');
    }
  };

  if ($('.js_cta').length) {
    cta.init();
  }
}
